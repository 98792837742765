/** @format */

import { createRouter, createWebHistory } from "vue-router";
const routes = [
	{
		path: "/",
		name: "主页",
		meta: {
			title: "主页",
		},
		component: () => import("@/views/home/Index"),
		children: [
			{
				path: "",
				name: "首页",
				meta: {
					title: "首页",
				},
				component: () => import("@/views/home/components/Home"),
			},
			{
				path: "job-list",
				name: "职位",
				meta: {
					title: "职位",
				},
				component: () => import("@/views/home/components/JobList"),
			},
			{
				path: "empty",
				name: "PersonalCenter",
				meta: {
					title: "个人中心",
				},
				component: () => import("@/views/home/components/PersonalCenter"),
			},
			{
				path: "/zh-cn/cookie-policy",
				name: "CookiePolicy",
				meta: {
					title: "Cookie 政策",
				},
				component: () => import("@/views/agreement/CookiePolicy"),
			},

			{
				path: "applicationRegistration",
				name: "ApplicationRegistration",
				meta: {
					title: "应聘登记表",
				},
				component: () =>
					import("@/views/home/components/ApplicationRegistration"),
			},
		],
	},
	{
		path: "/admin",
		name: "AdminIndex",
		meta: {
			title: "后台",
		},
		redirect: "/admin/home",
		component: () => import("@/views/admin/AdminIndex"),
		children: [
			{
				path: "home",
				name: "AdminHome",
				meta: {
					title: "首页",
				},
				component: () => import("@/views/admin/home/AdminHome"),
			},
			{
				path: "recruit-form",
				name: "PositionApplication",
				meta: {
					title: "岗位申请",
				},
				component: () => import("@/views/admin/recruit/PositionApplication"),
			},
			{
				path: "recruit-approval",
				name: "PositionApproval",
				meta: {
					title: "岗位申请审批",
				},
				component: () => import("@/views/admin/recruit/PositionApproval"),
			},
			{
				path: "position-list",
				name: "Position",
				meta: {
					title: "职位列表",
				},
				component: () => import("@/views/admin/recruit/Position"),
				children: [
					{
						path: "",
						name: "PositionList",
						meta: {
							title: "职位预览",
						},
						component: () =>
							import("@/views/admin/recruit/components/PositionList"),
					},
					{
						path: "details",
						name: "PositionDetails",
						meta: {
							title: "查看详情",
						},
						component: () =>
							import("@/views/admin/recruit/components/PositionDetails"),
					},
				],
			},
			{
				path: "sales-indicator-input",
				name: "SalesIndicatorInput",
				meta: {
					title: "销售指标录入",
				},
				component: () => import("@/views/admin/salesIndicatorInput/SalesIndicatorInput"),
			},
			{
				path: "resume-delivery",
				name: "ResumeIndex",
				meta: {
					title: "简历库",
				},

				component: () => import("@/views/admin/recruit/ResumeIndex"),
				children: [
					{
						path: "",
						name: "ResumeDelivery",
						meta: {
							title: "疯狂简历库",
						},
						component: () => import("@/views/admin/recruit/ResumeDelivery"),
					},
					{
						path: "interviewEvaluation",
						name: "InterviewEvaluation",
						meta: {
							title: "通用岗位面试评估表",
						},
						component: () =>
							import("@/views/home/components/InterviewEvaluation"),
					},
					{
						path: "interviewEvaluationManage",
						name: "InterviewEvaluationManage",
						meta: {
							title: "管理岗位面试评估表",
						},
						component: () =>
							import("@/views/home/components/InterviewEvaluationManage"),
					},
				],
			},
			{
				path: "permission",
				name: "PermissionView",
				meta: {
					title: "权限设定",
				},
				component: () => import("@/views/admin/permission/PermissionView"),
			},
			{
				path: "personnel",
				name: "PersonnelView",
				meta: {
					title: "人员管理",
				},
				component: () => import("@/views/admin/permission/PersonnelView"),
			},
			{
				path: "reportingSystem",
				name: "ReportForm",
				meta: {
					title: "报表系统",
				},
				component: () => import("@/views/admin/reportForm/ReportForm"),
			},
			{
				path: "talentPool",
				name: "TalentPool",
				meta: {
					title: "人才数据库",
				},
				component: () => import("@/views/admin/talentPool/TalentPool"),
			},
			{
				path: "battleRoom",
				name: "BattleRoom",
				meta: {
					title: "作战室",
				},
				component: () => import("@/views/admin/battleRoom/BattleRoom"),
			},
		],
	},
	{
		path: "/helps",
		name: "Doc",
		meta: {
			title: "帮助文档",
		},
		component: () => import("@/views/doc/Doc"),
		redirect: "/helps/doc1_1",
		children: [
			{
				path: "doc1_1",
				name: "Doc1_1",
				meta: {
					title: "Doc1_1",
				},
				component: () => import("@/views/doc/components/Doc1_1"),
			},
			{
				path: "doc1_2",
				name: "Doc1_2",
				meta: {
					title: "Doc1_2",
				},
				component: () => import("@/views/doc/components/Doc1_2"),
			},
		],
	},
	{
		path: "/login",
		name: "Login",
		meta: {
			title: "登录注册",
		},
		component: () => import("@/views/login/Login"),
	},
	{
		path: "/admin-login",
		name: "AdminLogin",
		meta: {
			title: "管理员登录界面",
		},
		component: () => import("@/views/admin/AdminLogin"),
	},
];
const router = createRouter({
	history: createWebHistory(),
	routes,
});
const unguarded = [
	"/",
	"/job-list",
	"/login",
	"/zh-cn/cookie-policy",
	"/empty",
];

export default router;
