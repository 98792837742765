/** @format */

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/base.less";

import {
	message,
	Menu,
	Button,
	Tooltip,
	Form,
	Input,
	Tag,
	Divider,
	Dropdown,
	Modal,
	TabPane,
	Tabs,
	Radio,
	RadioGroup,
	DatePicker,
	Select,
	SelectOption,
	Checkbox,
	Space,
	Upload,
	RangePicker,
	Textarea,
	Layout,
	LayoutSider,
	Progress,
	SubMenu,
	Breadcrumb,
	BreadcrumbItem,
	InputNumber,
	Table,
	Steps,
	Step,
	Pagination,
	Empty,
	Card,
	Badge,
	Drawer,
	TimePicker,
	Timeline,
	TimelineItem,
	Cascader,
	Image,
} from "ant-design-vue";
import VueCookies from "vue-cookies";
import { Popup, DropdownItem } from "vant";

const app = createApp(App);
app
	.use(store)
	.use(router)
	.use(Menu)
	.use(Button)
	.use(Tooltip)
	.use(Form)
	.use(Input)
	.use(Tag)
	.use(Divider)
	.use(Dropdown)
	.use(Modal)
	.use(TabPane)
	.use(Tabs)
	.use(Radio)
	.use(RadioGroup)
	.use(DatePicker)
	.use(Select)
	.use(Checkbox)
	.use(Space)
	.use(Upload)
	.use(RangePicker)
	.use(Textarea)
	.use(Layout)
	.use(LayoutSider)
	.use(Progress)
	.use(SubMenu)
	.use(Breadcrumb)
	.use(BreadcrumbItem)
	.use(InputNumber)
	.use(Table)
	.use(Steps)
	.use(Step)
	.use(SelectOption)
	.use(Popup)
	.use(DropdownItem)
	.use(Pagination)
	.use(Empty)
	.use(Card)
	.use(Badge)
	.use(Drawer)
	.use(TimePicker)
	.use(Timeline)
	.use(TimelineItem)
	.use(Cascader)
	.use(Image)
	.mount("#app");

app.config.globalProperties.$cookies = VueCookies;
app.config.globalProperties.$message = message;
