<template>
  <router-view />
</template>
<script>
import DevicePixelRatio from '@/utils/evicePixelRatio.js';
import { onMounted } from 'vue'


export default {
  setup() {
    onMounted(() => {


    })
    return {

    }
  }
}


</script>
<style lang="less" scoped>
html,
body,
body {
  padding-right: 0 !important;
  margin: 0;
}

body::-webkit-scrollbar {
  display: none;
}

html::-webkit-scrollbar {
  display: none;
}
@media (max-width: 600px) {
  .ant-picker-panels {
    display: flex;
  }
}
</style>
