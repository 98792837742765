/** @format */

export default {
	//开启命名空间
	namespaced: true,
	state: {
		interview: [],
	},
	mutations: {
		updateInterview(state, val) {
			state.interview = val;
		},
	},
	getters: {
		format(state) {
			return state.interview;
		},
	},
};
