/** @format */

import { createStore } from "vuex";
import timeline from "./modules/timeline";
export default createStore({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		timeline,
	},
});
